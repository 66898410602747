<template>
    <modal ref="modalLanzamientos" :titulo="`${title == 2 ? 'Lanzamientos programados' : 'Lanzamientos pausados'}`" tamano="modal-lg" no-aceptar no-cancelar>
        <tabla-general :data="dataTable" :columnas="dataColumns" class-header="text-general f-600 f-16" :usar-paginacion="false">
            <template slot="cabecera-izquierda">
                <div class="col-auto text-general f-17">
                    Lista
                </div>
            </template>
        </tabla-general>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import notificaciones from '~/services/publicidad/notificaciones'
export default {
    data(){
        return {
            dataColumns: [
                {valor: 'campana', titulo: 'Campaña', class: 'text-center'},
                {valor: 'lanzamiento', titulo: 'Lanzamiento', class: 'text-center'},
                {valor: 'fecha', titulo: 'Programado', class: 'text-center'},
                {valor: 'destinatarios', titulo: 'Destinatarios', class: 'text-center'}
            ],
            dataTable: [],
            title: 1,
        }
    },
    computed: {
        ...mapGetters({
            graficaModel: 'publicidad/notificaciones/graficaModel',
        })
    },
    methods: {
        async toggle(title){
            this.title = title
            await this.verLanzamientosEstadistica()
            this.$refs.modalLanzamientos.toggle();
        },
        async verLanzamientosEstadistica(){
            try {
                let params = {
                    carpeta: this.graficaModel.carpeta,
                    campana: this.graficaModel.campana,
                    tipo: this.graficaModel.tipo,
                    estado: this.title
                }
                const { data } = await notificaciones.verLanzamientosEstadistica(params);
                this.dataTable = data.lanzamientos.map(el => ({...el, fecha: this.formatearFecha(this.title == 2 ? el.fecha: el.updated_at, 'DD MMM YYYY hh:mm a')}))
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>